<template>
  <div class="row justify-content-center mt-3">
    <div class="col-md-6">
      <am-form-layout
        title="Contribution Payment"
        submit-text="Pay Now"
        :show-loading="loading"
        :show-reset="false"
        submit-button-name="generate-link"
        @onSubmit="payNow()"
      >
        <template slot="form-header">
          <div class="d-flex justify-content-center mb-2">
            <div class="d-flex flex-column justify-content-start">
              <div class="text-center mb-2">
                <am-profile-photo
                  size="80"
                  :photo="paymentDetails.photo"
                />
              </div>
              <div class="d-flex">
                <strong class="mr-1">
                  Dues:
                </strong>
                <span> Member Dues</span>
              </div>
              <div class="d-flex">
                <strong class="mr-1">
                  Contributor:
                </strong>
                <span> Micheail Osui Gyan</span>
              </div>
              <div class="d-flex text-danger">
                <strong class="mr-1">
                  Outstanding (GHS):
                </strong>
                <span> 100.00</span>
              </div>
            </div>
          </div>

        </template>
        <template slot="form-elements">
          <div class="col-12">
            <am-input
              v-model="paymentDetails.amount"
              name="amount"
              label="Amount"
              placeholder="Please enter an amount to pay."
              :rules="`required|max_value:${paymentDetails.pending_amount}`"
            />
          </div>
          <div class="col-12">
            <error-display :error="error" />
          </div>
        </template>
      </am-form-layout>
    </div>
  </div>
</template>

<script>
import AmFormLayout from '@/farmbusiness/components/layouts/AmFormLayout.vue'
import AmProfilePhoto from '@/farmbusiness/components/AmProfilePhoto.vue'
import AmInput from '@/farmbusiness/components/AmInput.vue'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay.vue'
import showToast from '@/farmbusiness/mixins/showToast'

export default {
  name: 'DuesPayment',
  components: {
    ErrorDisplay,
    AmInput,
    AmProfilePhoto,
    AmFormLayout,
  },
  mixins: [showToast],
  data() {
    return {
      paymentDetails: {
        photo: null,
        amount: null,
        pending_amount: null,
      },
      error: {},
      paymentPayload: null,
      loading: false,
    }
  },
  watch: {
    paymentPayload(val) {
      if (val) {
        this.getPaymentDetails(val)
      } else {
        this.$router.push('/error-404')
      }
    },
  },
  mounted() {
    this.paymentPayload = this.$route.query.d
  },
  methods: {
    getPaymentDetails(payload) {
      this.loading = true
      this.$store.dispatch('contributionPayments/getPaymentDetails', payload).then(res => {
        this.paymentDetails = { ...this.paymentDetails, ...res }
      }).catch(err => {
        this.error = err
        this.showError('Failed to retrieve payment details, contact support.')
        this.$router.push('/error-404')
      }).finally(() => {
        this.loading = false
      })
    },
    payNow() {
      this.loading = true
      this.$store.dispatch('contributionPayments/initiatePayment', this.paymentDetails)
        .then(res => {
          window.open(res)
        })
        .catch(error => {
          this.error = error
          this.showError('Failed to initialize payment, contact support')
        }).finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>

</style>
